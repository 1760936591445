import React, {ReactElement} from 'react';
import PropTypes, {InferProps} from 'prop-types';
import Styles from './FormSelect.scss';
import {Field as FormikField, FieldProps} from 'formik';
import classnames from 'classnames';

const fieldProps = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
};

type Props<FormFields> = Omit<InferProps<typeof fieldProps>, 'options'> & {
  name: {
    [F in keyof FormFields]: FormFields[F] extends object ? never : F
  }[keyof FormFields];
  options: {
    value: string | number;
    label: string;
  }[];
};

class FormSelect<FormFields> extends React.Component<Props<FormFields>, object> {
  static propTypes = fieldProps;

  render(): React.ReactElement {
    const props = this.props;
    return <div className={classnames(Styles.selectWrapper)}>
        <FormikField
          name={props.name}
        >
          {({
              field,
              meta,
            }: FieldProps<string>): ReactElement => (
              <select
                className={classnames(Styles.select, {[Styles.selectError]: meta.touched && meta.error})}
                {...field}
              >
                {props.options.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            )
          }
        </FormikField>
        <label className={Styles.label}>{props.label}</label>
      </div>
  }
}

export default FormSelect;