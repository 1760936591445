import React from 'react';
import Styles from './Footer.scss';
import Logo, {LogoVariant} from '../../logo/Logo';

export default class Footer extends React.Component<object, object> {
  render(): React.ReactElement {
    return <footer className={Styles.footerContainer}>
      <div className={Styles.footer}>
        <span className={Styles.footerLabel}>Powered by</span>
        <Logo variant={LogoVariant.NEXTBANK_SMALL} />
      </div>
    </footer>
  }
}