import {format, formatISO, parseISO} from 'date-fns'

class DateUtil {
  formatDateISO(date: Date): string {
    return formatISO(date, {representation: 'date'})
  }

  formatDateUi(date: Date): string {
    return format(date, 'dd.MM.yyyy');
  }

  parseISO(date: string): Date {
    return parseISO(date);
  }
}

export default new DateUtil();