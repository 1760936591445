import PropTypes, {InferProps} from 'prop-types';
import React from 'react';
import Styles from './Logo.scss';
import LogoDiskartech from '../../static/assets/images/logo-diskartech.png';
import LogoNextbank from '../../static/assets/images/logo-nextbank.svg';
import LogoRCBC from '../../static/assets/images/logo-rcbc-sygnet.png';

export enum LogoVariant {
  DISKARTECH = 'diskartech',
  NEXTBANK = 'nextbank',
  NEXTBANK_SMALL = 'nextbank-small',
  RCBC = 'rcbc'
}
const props = {
  variant: PropTypes.string.isRequired
};

type Props = Omit<InferProps<typeof props>, 'variant'> & {
  variant: LogoVariant;
};

export default class Logo extends React.Component<Props, object> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactElement {
    const imgAttributes = {
      [LogoVariant.NEXTBANK]: {
        className: Styles.logo,
        src: LogoNextbank,
        alt: 'Nextbank'
      },
      [LogoVariant.NEXTBANK_SMALL]: {
        className: `${Styles.logo} ${Styles.logoNextbankSmall}`,
        src: LogoNextbank,
        alt: 'Nextbank'
      },
      [LogoVariant.DISKARTECH]: {
        className: `${Styles.logo} ${Styles.logoDiskartech}`,
        src: LogoDiskartech,
        alt: 'Diskartech'
      },
      [LogoVariant.RCBC]: {
        className: `${Styles.logo} ${Styles.logoRcbc}`,
        src: LogoRCBC,
        alt: 'RCBC'
      }
    }

    return <img {...imgAttributes[this.props.variant]} />;
  }
}