import React from 'react';
import http from '../http';
import createLogger from '../logger';
import Footer from './footer/Footer';
import TopBar from './topBar/TopBar';
import ProgressBar from './progressBar/ProgressBar';
import StartStep from './startStep/StartStep';
import {Beneficiary} from './Beneficiary';
import ValidateStep from './validateStep/ValidateStep';
import ConfirmStep, {ConfirmationDetails} from './confirmStep/ConfirmStep';
import DoneStep from './doneStep/DoneStep';
import UpdateStep from './updateStep/UpdateStep';

interface Session {
  admin: boolean;
  sessionIdleTime: number;
  username: string;
}

export enum Step {
  START = 'Start',
  VALIDATE = 'Validate',
  UPDATE = 'Update',
  CONFIRM = 'Confirm',
  DONE = 'Done'
}

interface State {
  step: Step;
  beneficiary: Beneficiary | null;
  isAdmin?: boolean;
}

const logger = createLogger('disbursement');

const steps: Step[] = [Step.START, Step.VALIDATE, Step.UPDATE, Step.CONFIRM, Step.DONE];
export default class Disbursement extends React.Component<object, State> {

  constructor(props: object) {
    super(props);

    this.state = {
      step: Step.START,
      beneficiary: null,
      isAdmin: undefined
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      const response: Session = await http.fetch('/auth/session', {
        method: 'GET',
      });

      this.setState({
        isAdmin: response.admin
      });
    } catch (e) {
      logger.error('Error on getting session', e);
    }
  }

  onBeneficiaryRegistered = (beneficiary: Beneficiary): void => {
    this.setState({
      step: Step.VALIDATE,
      beneficiary,
    })
  };

  onBeneficiaryValidated = (): void => {
    this.setState({
      step: Step.UPDATE
    })
  };

  onDataUpdated = (): void => {
    this.setState({
      step: Step.CONFIRM
    })
  };

  onBeneficiaryConfirmationChanged = (confirmation: ConfirmationDetails): void => {
    this.setState({
      step: confirmation.confirmed ? Step.DONE : Step.VALIDATE
    })
  };

  render(): React.ReactElement {
    return <>
      <TopBar newClientButtonVisible={this.state.step !== Step.START}
              configurationButtonVisible={this.state.isAdmin}
              loaded={this.state.isAdmin !== undefined} />
      <ProgressBar steps={steps} step={this.state.step}/>
      {
        this.state.step === Step.START && (
          <StartStep onBeneficiaryRegistered={this.onBeneficiaryRegistered} />
        )
      }
      {
        this.state.step === Step.VALIDATE && (
          <ValidateStep
            onBeneficiaryValidated={this.onBeneficiaryValidated}
            beneficiary={this.state.beneficiary as Beneficiary}
          />
        )
      }
      {
        this.state.step === Step.UPDATE && (
          <UpdateStep
            beneficiary={this.state.beneficiary as Beneficiary}
            onDataUpdated={this.onDataUpdated}
          />
        )
      }
      {
        this.state.step === Step.CONFIRM && (
          <ConfirmStep
            onBeneficiaryConfirmationChanged={this.onBeneficiaryConfirmationChanged}
            beneficiary={this.state.beneficiary as Beneficiary}
          />
        )
      }
      {
        this.state.step === Step.DONE && (
          <DoneStep />
        )
      }
    <Footer />
    </>
  }
}