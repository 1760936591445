import React from 'react';
import {arrayOf, InferProps, string} from 'prop-types';
import Styles from './ProgressBar.scss';
import classnames from 'classnames'

const props = {
  step: string.isRequired,
  steps: arrayOf(string).isRequired
};

type Props = InferProps<typeof props>;

export default class ProgressBar extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    const stepIndex = this.props.steps.indexOf(this.props.step);
    return <div className={Styles.progressBar}>
      {
        this.props.steps.map((step, index) => {
          return <div className={classnames(Styles.progressStep, {
            [Styles.progressStepChecked]: index < stepIndex,
            [Styles.progressStepActive]: index === stepIndex
          }
            )}
            key={index}
          >
            <span className={Styles.number}>{stepIndex > index ? ' ' : (index + 1)}</span>
            <div className={Styles.label}>{step}</div>
          </div>
        })
      }
    </div>
  }
}