import React from 'react';
import {InferProps, string,} from 'prop-types';
import Styles from './Alert.scss';
import classnames from 'classnames';

const props = {
  children: string.isRequired,
  variant: string
};


type Props = Omit<InferProps<typeof props>, 'variant'> & {
  variant?: 'rightAligned' | 'fullWidth' | 'leftAligned';
};

export default class Alert extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    return <section className={classnames(Styles.alert, {
      [Styles.fullWidth] : this.props.variant === 'fullWidth',
      [Styles.rightAligned] : this.props.variant === 'rightAligned',
      [Styles.leftAligned] : this.props.variant === 'leftAligned'
    })}>
      <div className={Styles.label}>Failure</div>
      <div>{this.props.children}</div>
    </section>
  }
}