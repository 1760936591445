import React from 'react';
import PropTypes, {InferProps, } from 'prop-types';
import classnames from 'classnames';
import Styles from './Amount.scss';

export type ComponentVariants = 'inline' | 'hero'

const props = {
  value: PropTypes.number.isRequired,
  variant: PropTypes.string
};

type Props = Omit<InferProps<typeof props>, 'variant'> & {
  variant?: ComponentVariants;
};

export default class Amount extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    const peso = this.props.value.toFixed(0);
    const formattedPeso = peso.replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, '$& ');
    const formattedCentavo = ((this.props.value * 100) % 100).toFixed(0).padStart(2, '0');
    const isHero = this.props.variant === 'hero';
    if(isHero) {
      return <p className={classnames(Styles.amount, Styles.hero)}>
        {formattedPeso}
        <span className={Styles.smallText}> ,{formattedCentavo} PHP</span>
      </p>
    }

    return <span>{formattedPeso}, {formattedCentavo} PHP</span>
  }
}