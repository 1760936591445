import React from 'react';
import {bool, func, InferProps, oneOf, string, element, oneOfType} from 'prop-types';
import classnames from 'classnames';
import Styles from './Button.scss';
import Alert from '../alert/Alert';

export type Variant = 'normal' | 'outline';

const props = {
  type: oneOf(['submit', 'button']).isRequired,
  children: oneOfType([string, element]).isRequired,
  fullWidth: bool,
  disabled: bool,
  error: string,
  onClick: func,
  variant: oneOf(['normal', 'outline'])
};


type Props = Omit<Omit<InferProps<typeof props>, 'type'>, 'variant'> & {
  type: 'submit' | 'button';
  variant?: Variant;
}

export default class Button extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    return <div className={classnames(Styles.wrapper, {
      [Styles.fullWidth]: this.props.fullWidth,
    })}>
      <div className={classnames(
        Styles.buttonWrapper, {
           [Styles.fullWidth]: this.props.fullWidth,
          }
        )}>
        <button
         type={this.props.type}
         disabled={this.props.disabled ?? false}
         className={classnames(Styles.button, {
           [Styles.fullWidth]: this.props.fullWidth,
           [Styles.buttonOutline]: this.props.variant === 'outline'
         })}
         onClick={this.props.onClick ?? ((): boolean => true)}
        >
          {this.props.children}
        </button>
      </div>
      { this.props.error && <Alert variant={this.props.fullWidth ? 'fullWidth' : 'rightAligned'} >{this.props.error}</Alert>}
    </div>
  }
}