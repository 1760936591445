import React, {ReactElement} from 'react';
import {MemoryRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Login from './login/Login';
import Disbursement from './disbursement/Disbursement';
import authStore, {LoginState} from './authStore';
import auth from './auth';

interface State {
  loginState: LoginState;
}

export default class App extends React.Component<object, State> {
  constructor(props: object) {
    super(props);

    this.state = {
      loginState: 'UNKNOWN',
    }
  }

  componentDidMount(): void {
    auth.checkSession();

    authStore.onLoginStateChanged(loginState => {
      this.setState({
        loginState,
      })
    })
  }

  render(): ReactElement {
    if (this.state.loginState === 'UNKNOWN') {
      return <div></div>;
    }

    const isLoggedIn = this.state.loginState === 'LOGGED_IN';
    return <Router>
      <Switch>
        <Route path='/login'>
          <Login/>
        </Route>
        {
          isLoggedIn && (
            <Route path='/disbursement'>
              <Disbursement/>
            </Route>
          )
        }
        {
          isLoggedIn && (
            <Route path='/configuration'>
              <Configuration />
            </Route>
          )
        }
        <Redirect to={isLoggedIn ? '/disbursement' : '/login'}/>
      </Switch>
    </Router>
  }
}