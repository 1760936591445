import React, {ReactElement} from 'react';

import PropTypes, {InferProps} from 'prop-types';
import {Beneficiary} from '../Beneficiary';
import StepContainer from '../stepContainer/StepContainer';
import FormInput from '../../form/FormInput';
import {Formik, FormikHelpers} from 'formik';
import Form from '../../form/Form';
import Button from '../../button/Button';
import { documentTypes } from './documentTypes';
import FormSelect from '../../form/FormSelect';
import http, {HttpError} from '../../http';
import createLogger from '../../logger';

const props = {
  beneficiary: PropTypes.object.isRequired,
  onDataUpdated: PropTypes.func.isRequired
};

type Props = Omit<InferProps<typeof props>, 'beneficiary' | 'onDataUpdated'> & {
  beneficiary: Beneficiary;
  onDataUpdated: () => void;
};

interface UpdateStepFields {
  documentType: string;
  documentNumber: string;
  expirationDate: string;
  mobileNo: string | undefined;
  server?: string;
}

const defaultDocumentType = 'ID8';

const logger = createLogger('updateStep');
export default class UpdateStep extends React.Component<Props, object> {
  static propTypes = props;

  constructor(props: Props) {
    super(props);
  }

  submit = async (values: UpdateStepFields, config: FormikHelpers<UpdateStepFields>): Promise<void> => {
    try {
      const query = new URLSearchParams();
      query.append('referenceNo', this.props.beneficiary.referenceNo);

      await http.fetch('/beneficiaries/update-data?' + query.toString(), {
        method: 'POST',
        body: JSON.stringify({
          idDocument: {
            type: values.documentType,
            number: values.documentNumber,
            expirationDate: values.expirationDate,
          },
          mobileNo: values.mobileNo ? values.mobileNo : null
        })
      });

      this.props.onDataUpdated();
    } catch (e) {
      logger.error('Error on update step', e);
      let errorMessage = 'Unknown error';
      if(e instanceof HttpError) {
        if(e.response.status === 404) {
          errorMessage = 'Reference number doesn\'t exist';
        } else {
          errorMessage = e.error.errorMessage;
        }
      }

      config.setFieldError('server', errorMessage);
    }
  };

  render(): React.ReactElement {
    return <StepContainer label="Enter Beneficiary ID document data">
      <Formik<Partial<UpdateStepFields>>
        initialValues={{
          documentType: defaultDocumentType,
          expirationDate: '',
          documentNumber: '',
          mobileNo: ''
        }}
        onSubmit={this.submit}
    >
      {({
          errors
        }): ReactElement => (
        <Form>
          <FormSelect<UpdateStepFields>
            label="Document type"
            options={documentTypes}
            name="documentType"
          />
          <FormInput<UpdateStepFields>
            label="Document Number"
            name="documentNumber"
          />
          <FormInput<UpdateStepFields>
            label="Expiration Date"
            type="date"
            name="expirationDate"
          />
          {
            !this.props.beneficiary.mobileNo &&
              <FormInput<UpdateStepFields>
                label="Phone number"
                name="mobileNo"
              />
          }
          <Button
            type="submit"
            error={errors.server}
          >
            Proceed
          </Button>
        </Form>
      )}
    </Formik>
    </StepContainer>
  }
}