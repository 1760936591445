import './logger';
import createLogger from './logger';
import authStore from './authStore';

const logger = createLogger('http');

type ErrorBody = {
  errorTimestamp: string;
  errorId: string;
  commandId: string;
  requestUuid: string;
  path: string;
  username: string;
  systemVersion: string;
  errorCode: string;
  errorMessage: string;
} | {
  errorMessage: string;
  customError: true;
}

export class HttpError extends Error {
  constructor(public response: Response, public error: ErrorBody) {
    super(`Http Error\nStatus: ${response.status}\n${error ? JSON.stringify(error) : ''}`)
  }
}

class HttpService {

  async fetchRaw(input: string, init?: RequestInit): Promise<Response> {
    /* eslint-disable-next-line  no-undef */
    let contentType = null;
    if(init && typeof init.body === 'string') {
      contentType = (init?.headers as Record<string, string>)?.['content-type'] ?? 'application/json';
    }

    const res = await fetch(__API_URL + input, {
      headers: {
        ...(contentType !== null ? {'content-type': contentType} : {}),
        ...(init?.headers ?? {})
      },
      ...init
    });
    if(res.ok) {
      return res;
    }

    if(res.status === 504) {
      authStore.logout();
    }

    return await this.unmarshalAndThrowError(res);
  }

  async fetch<Res>(url: string, init?: RequestInit): Promise<Res> {
    const res = await this.fetchRaw(url, init);

    const text = await res.text();
    if(text && text != '') {
      return JSON.parse(text);
    }

    // consider empty response as a correct json
    return null as unknown as Res;
  }

  private async unmarshalAndThrowError(res: Response): Promise<never> {
    let responseErrorBody;
    const text = await res.text();
    try {
      responseErrorBody = JSON.parse(text);
    } catch (e) {
      logger.warn('Error demarshalling backend error', e);
      throw new HttpError(res, {
        customError: true,
        errorMessage: text
      });
    }

    throw new HttpError(res, responseErrorBody);
  }
}

export default new HttpService();