import {InferProps, bool} from 'prop-types';
import React from 'react';
import Styles from './TopBar.scss';
import Logo, {LogoVariant} from '../../logo/Logo';
import Link from '../../link/Link';
import Button from '../../button/Button';
import authStore from '../../authStore';
import createLogger from '../../logger';
import http from '../../http';

const props = {
  loaded: bool,
  newClientButtonVisible: bool,
  configurationButtonVisible: bool
};

type Props = InferProps<typeof props>;

const logger = createLogger('topBar');

export default class TopBar extends React.Component<Props, object> {
  logout = async (): Promise<void> => {
    try {
      await http.fetchRaw('/auth/logout', {
        method: 'POST'
      });

      authStore.logout();
      logger.info('Logged out');
    } catch(e) {
      logger.error('Cannot log out', e);
    }
  };

  render(): React.ReactElement {
    return <nav className={Styles.topBarContainer}>
      <div className={Styles.topBar}>
        <Logo variant={LogoVariant.RCBC} />
        <Logo variant={LogoVariant.DISKARTECH} />
        <div className={Styles.buttons}>
          {!this.props.loaded ?
            <p className={Styles.loading}>loading...</p> : <>
              {
                this.props.newClientButtonVisible &&
                <Link to="/" variant="button">
                  New Client
                </Link>
              }
              {
                this.props.configurationButtonVisible &&
                <Link to="/configuration" variant="button">
                  Configuration
                </Link>
              }
              <Button variant="outline" type="button" onClick={this.logout}>
                Logout
              </Button>
            </>
          }
        </div>
      </div>
    </nav>
  }
}