import React, {ReactElement} from 'react';

import PropTypes, {InferProps} from 'prop-types';
import {Beneficiary} from '../Beneficiary';
import StepContainer from '../stepContainer/StepContainer';
import Button from '../../button/Button';
import Amount from '../../amount/Amount';
import Styles from './ConfirmStep.scss';
import http, {HttpError} from '../../http';
import createLogger from '../../logger';
import Alert from '../../alert/Alert';

export interface ConfirmationDetails {
  confirmed: boolean;
}

const props = {
  beneficiary: PropTypes.object.isRequired,
  onBeneficiaryConfirmationChanged: PropTypes.func.isRequired
};

type Props = Omit<InferProps<typeof props>, 'beneficiary' | 'onBeneficiaryConfirmationChanged'> & {
  beneficiary: Beneficiary;
  onBeneficiaryConfirmationChanged: (confirmationDetails: ConfirmationDetails) => void;
};

interface State {
  submitting: boolean;
  serverError: string | null;
}

const logger = createLogger('confirmStep');
export default class ConfirmStep extends React.Component<Props, State> {
  static propTypes = props;

  constructor(props: Props) {
    super(props);

    this.state = {
      submitting: false,
      serverError: null,
    }
  }

  submit = async (): Promise<void> => {
    try {
      this.setState({
        submitting: true
      });

      await http.fetch(`/beneficiaries/${this.props.beneficiary.id}/disburse-subsidy`, {
        method: 'POST'
      });

      this.props.onBeneficiaryConfirmationChanged({confirmed: true})
    } catch (e) {
      this.setState({
        submitting: false
      });

      logger.error('Error on disbursing subsidiary', e);
      let errorMessage = 'Unknown error';
      if(e instanceof HttpError) {
        errorMessage = e.error.errorMessage;
      }

      this.setState({
        serverError: errorMessage,
      });
    }
  };

  render(): React.ReactElement {
    const  beneficiary = this.props.beneficiary;
    const beneficiaryName = [
      beneficiary.firstName,
      beneficiary.middleName,
      beneficiary.lastName,
    ].filter(Boolean)
      .join(' ');

    return <StepContainer label="Disburse Now">
      <p><Amount value={this.props.beneficiary.subsidyAmount} /> to {beneficiaryName}.</p>
      <p className={Styles.text}>Have you verified customer ID with the data presented on the previous screen?</p>
      <p className={Styles.tooltip}>
        <span className={Styles.help} />
        <span className={Styles.tooltipText}>If customer does not have valid ID present collect the required certification.</span>
      </p>
     <div className={Styles.buttons}>
        <Button
          onClick={this.submit}
          type='button'
          disabled={this.state.submitting}
        >
          Yes
        </Button>
        <Button
          variant='outline'
          onClick={(): void =>
            this.props.onBeneficiaryConfirmationChanged({confirmed: false})
          }
          type='button'
          disabled={this.state.submitting}
        >
          No
        </Button>
      </div>
      {
        !!this.state.serverError && <Alert variant="leftAligned" >{this.state.serverError}</Alert>
      }
    </StepContainer>
  }
}