const prefixHandler = (name: string): ProxyHandler<Console> => ({
  get: (target: Console, objectKey: keyof Console): ((args: object[]) => void) => {
    return (...args: object[]): void =>
      Reflect.get(target, objectKey).apply(target, [`[${name}]`, ...args]);
  }
});

export default (name: string): Console => {
  return new Proxy(window.console, prefixHandler(name));
};
