export const documentTypes = [
  {
    value: 'NSO',
    label: 'NSO Birth Certificate'
  },
  {
    value: 'DEPED',
    label: 'Department of Education DEPED'
  },
  {
    value: 'ACP',
    label: 'Articles of Co-Partnership'
  },
  {
    value: 'AOF',
    label: 'Account Opening Form'
  },
  {
    value: 'AOI',
    label: 'Articles of Incorporation'
  },
  {
    value: 'BLS',
    label: 'By-Laws'
  },
  {
    value: 'BPL',
    label: 'Business Permits and Licenses'
  },
  {
    value: 'CDA',
    label: 'CDA Certificate of Registration'
  },
  {
    value: 'COL',
    label: 'Certificate of Employment'
  },
  {
    value: 'COR',
    label: 'Current OR of the reg. of MV'
  },
  {
    value: 'CRMV',
    label: 'Cert of registration of motor vehicle'
  },
  {
    value: 'CRT',
    label: 'Certification of realty tax payment'
  },
  {
    value: 'CTC',
    label: 'Cedula'
  },
  {
    value: 'CTCT',
    label: 'Certified copy of orig TCT w/ ROD'
  },
  {
    value: 'DAS',
    label: 'Deed of Sale (for newly acquired prop)'
  },
  {
    value: 'DOLE',
    label: 'DOLE Certificate of Registration'
  },
  {
    value: 'FAT',
    label: 'FATCA Questionnaire'
  },
  {
    value: 'FATC',
    label: 'FATCA Cert Consent & Waiver Individual'
  },
  {
    value: 'FATC1',
    label: 'FATCA W-9 Request for TIN & Certification'
  },
  {
    value: 'FATC2',
    label: 'FATCA W-8BEN Cert of Foreign Status'
  },
  {
    value: 'PIC',
    label: '2x2 Latest Picture'
  },
  {
    value: 'FATCA',
    label: 'FATCA Cert Consent & Waiver Juridical'
  },
  {
    value: 'FS1',
    label: 'FS for loan over 50k to 150k'
  },
  {
    value: 'GIS',
    label: 'General Information Sheet'
  },
  {
    value: 'ID27',
    label: 'Others'
  },
  {
    value: 'IFS',
    label: 'FS Certified by CPA (over 150k loan)'
  },
  {
    value: 'ITR',
    label: 'Income Tax Return'
  },
  {
    value: 'LPE',
    label: 'Latest Picture of Establishment'
  },
  {
    value: 'LPVM',
    label: 'Location Plan w/ Vicinity Map'
  },
  {
    value: 'MCT',
    label: 'Marriage Certificate'
  },
  {
    value: 'NAO',
    label: 'Notarized Articles of Organization'
  },
  {
    value: 'AOP',
    label: 'Articles of Partnership / Incorporation'
  },
  {
    value: 'SECP',
    label: 'SEC Permit'
  },
  {
    value: 'MBP',
    label: 'Mayor’s / Barangay Permit'
  },
  {
    value: 'DTI',
    label: 'DTI Registration Certificate'
  },
  {
    value: 'PRN',
    label: 'Partnership / Board Resolution'
  },
  {
    value: 'PICM',
    label: 'Picture of the motor vehicle'
  },
  {
    value: 'ID1',
    label: 'Passport'
  },
  {
    value: 'ID2',
    label: 'Driver\'s License'
  },
  {
    value: 'ID3',
    label: 'PRC ID'
  },
  {
    value: 'ID4',
    label: 'NBI Clearance'
  },
  {
    value: 'ID5',
    label: 'Police Clearance'
  },
  {
    value: 'ID6',
    label: 'Postal ID'
  },
  {
    value: 'ID7',
    label: 'Voter\'s ID'
  },
  {
    value: 'ID8',
    label: 'TIN'
  },
  {
    value: 'ID9',
    label: 'Barangay Certification'
  },
  {
    value: 'ID10',
    label: 'GSIS e-Card/UMID'
  },
  {
    value: 'ID11',
    label: 'SSS'
  },
  {
    value: 'ID12',
    label: 'Senior Citizen Card'
  },
  {
    value: 'ID13',
    label: 'Overseas Worker Welfare Admin (OWWA) ID'
  },
  {
    value: 'ID14',
    label: 'OFW ID'
  },
  {
    value: 'ID15',
    label: 'Seaman\'s Book'
  },
  {
    value: 'ID16',
    label: 'Alien/Immigrant Certificate of Reg.'
  },
  {
    value: 'ID17',
    label: 'Government ID/GOCC ID'
  },
  {
    value: 'ID18',
    label: 'Cert. from National Council (NCWDP)'
  },
  {
    value: 'ID19',
    label: 'DSWD Certification'
  },
  {
    value: 'ID20',
    label: 'Integrated Bar of the Phil. (IBP) ID'
  },
  {
    value: 'ID21',
    label: 'Company ID'
  },
  {
    value: 'ID22',
    label: 'Student\'s ID'
  },
  {
    value: 'ID23',
    label: 'National ID'
  },
  {
    value: 'ID24',
    label: 'SEC Certification of Registration'
  },
  {
    value: 'ID25',
    label: 'Business Registration Certificate'
  },
  {
    value: 'ID26',
    label: 'Philhealth'
  },
  {
    value: 'HDMF',
    label: 'Pag-IBIG HDMF'
  },
  {
    value: 'NSC',
    label: 'Notarized Secretary\'s Cert/Board Resolution'
  },
  {
    value: 'PAYS',
    label: 'Pay Slip'
  },
  {
    value: 'PCAB',
    label: 'Contractors License (if Contractor)'
  },
  {
    value: 'RTR',
    label: 'Realty Tax Receipt for current year'
  },
  {
    value: 'SMCN',
    label: 'Stencil of motor and Chassis numbers'
  },
  {
    value: 'SPA',
    label: 'SPA if owner is not the borrower'
  },
  {
    value: 'SREC',
    label: 'Service Record'
  },
  {
    value: 'TAC',
    label: 'Terms and Conditions'
  },
  {
    value: 'TCT',
    label: 'TCT/OCT free from liens and encumbrances'
  },
  {
    value: 'TD1',
    label: 'Tax Declaration of lot and bldg.'
  },
  {
    value: 'VID',
    label: 'Valid ID'
  },
  {
    value: 'SAC_ID',
    label: 'SAC ID'
  }
]