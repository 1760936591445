import React from 'react';

import RefNumberSection, {IdentificationMethod} from './RefNumberSection';
import OtpSection from './OtpSection';

import BirthDateSection from './BirthDateSection';
import {func, InferProps} from 'prop-types';
import {Beneficiary} from '../Beneficiary';
import StepContainer from '../stepContainer/StepContainer';


const props = {
  onBeneficiaryRegistered: func.isRequired
};

type Props = Omit<InferProps<typeof props>, 'onBeneficiaryRegistered'> & {
  onBeneficiaryRegistered: (beneficiary: Beneficiary) => void;
}

interface State {
  referenceNumber: string | null;
  identificationMethod: IdentificationMethod | null;
}

export default class StartStep extends React.Component<Props, State> {
  static propTypes = props;

  constructor(props: Props) {
    super(props);

    this.state = {
      referenceNumber: null,
      identificationMethod: null
    }
  }

  onStartIdentification = (referenceNumber: string, identificationMethod: IdentificationMethod): void => {
   this.setState({
     referenceNumber,
     identificationMethod
   })
  };

  render(): React.ReactElement {
    return <StepContainer label="Access beneficiary information">
      <RefNumberSection
        onStartIdentification={this.onStartIdentification}
        disabled={!!this.state.referenceNumber}
      />
      { this.state.identificationMethod === 'OTP' && <OtpSection
          onBeneficiaryRegistered={this.props.onBeneficiaryRegistered}
          referenceNumber={this.state.referenceNumber as string}
        />
      }

      { this.state.identificationMethod === 'BIRTH_DATE' && <BirthDateSection
          onBeneficiaryRegistered={this.props.onBeneficiaryRegistered}
          referenceNumber={this.state.referenceNumber as string}
        />
      }
    </StepContainer>
  }
}