import createLogger from './logger';
type AuthListener = (loginState: LoginState) => void;
export type LoginState = 'UNKNOWN' | 'LOGGED_IN' | 'LOGGED_OUT';

const logger = createLogger('authStore');
class AuthStore {
  private loginState: LoginState = 'UNKNOWN';
  private authEventListeners: AuthListener[] = [];

  login(): void {
    this.loginState = 'LOGGED_IN';
    this.notifyAll();
  }

  logout(): void {
    this.loginState = 'LOGGED_OUT';
    this.notifyAll();
  }

  onLoginStateChanged(callback: AuthListener): void {
    this.authEventListeners.push(callback);

    if (this.loginState !== 'UNKNOWN') {
      callback(this.loginState);
    }
  }

  private notifyAll(): void {
    for(const cb of this.authEventListeners) {
      try {
        cb(this.loginState);
      } catch (e) {
        logger.error('Error notifying about auth change', e);
      }
    }
  }
}

export default new AuthStore();