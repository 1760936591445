import React from 'react';
import {arrayOf, bool, element, InferProps, oneOfType, string} from 'prop-types';
import Styles from './StepContainer.scss';

const props = {
  children: oneOfType([
    element,
    arrayOf(
      oneOfType([
        element,
        bool
      ])
    )
  ]).isRequired,
  label: string.isRequired
};

type Props = InferProps<typeof props>;

export default class StepContainer extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    return <main className={Styles.stepContainer}>
      <div className={Styles.box}>
        <p className={Styles.header}>{this.props.label}</p>
        {this.props.children}
      </div>
    </main>
  }
}