import http from './http';
import authStore from './authStore';
import createLogger from './logger';

const logger = createLogger('auth');
class Auth {
  async checkSession(): Promise<void> {
    try {
      await http.fetchRaw('/auth/session', {
        method: 'GET',
      });

      logger.info('Merchant has a valid session');
      authStore.login();
    } catch(e) {
      logger.warn('Merchant session has expired', e);
      authStore.logout();
    }
  }

  async login(email: string, password: string): Promise<void> {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    await http.fetchRaw('/auth/login', {
      method: 'POST',
      body: formData
    });

    logger.info('Logged in');
    authStore.login();
  }
}

export default new Auth();