import React, {ReactElement} from 'react';

import PropTypes, {InferProps} from 'prop-types';
import {Beneficiary} from '../Beneficiary';
import StepContainer from '../stepContainer/StepContainer';
import FormInput from '../../form/FormInput';
import {Formik} from 'formik';
import Form from '../../form/Form';
import Button from '../../button/Button';
import dateUtil from '../../date';
import Amount from '../../amount/Amount';


const props = {
  beneficiary: PropTypes.object.isRequired,
  onBeneficiaryValidated: PropTypes.func.isRequired
};

type Props = Omit<InferProps<typeof props>, 'beneficiary' | 'onBeneficiaryValidated'> & {
  beneficiary: Beneficiary;
  onBeneficiaryValidated: () => void;
};

type BeneficiaryDetails = Beneficiary & {
  birthDateFormatted: string;
  addressFormatted: string;
}

export default class ValidateStep extends React.Component<Props, object> {
  static propTypes = props;

  constructor(props: Props) {
    super(props);
  }

  formattedAddress(beneficiary: Beneficiary): string {
    return [
      beneficiary.addressStreet,
      [
        beneficiary.addressBarangay,
        beneficiary.addressCity,
        beneficiary.addressPostalCode,
        beneficiary.addressProvince
      ].filter(Boolean).join(' ')
    ].filter(Boolean)
      .join(', ');
  }

  submit = (): void => {
    this.props.onBeneficiaryValidated();
  };

  render(): React.ReactElement {
    const label = 'Validate the data against the document.';
    const birthDate = dateUtil.parseISO(this.props.beneficiary.birthDate);

    return <StepContainer label="Beneficiary information">
      <p>Reference number: {this.props.beneficiary.referenceNo}</p>
      <Formik<BeneficiaryDetails>
        initialValues={{
          birthDateFormatted: dateUtil.formatDateUi(birthDate),
          addressFormatted: this.formattedAddress(this.props.beneficiary),
          ...this.props.beneficiary
        }
      }
      onSubmit={this.submit}
    >
      {({
          values
        }): ReactElement => (
        <Form>
          <FormInput<BeneficiaryDetails>
            tooltip={label}
            label="Beneficiary first name"
            name="firstName"
            disabled={true}
          />
          <FormInput<BeneficiaryDetails>
            tooltip={label}
            label="Beneficiary middle name"
            name="middleName"
            disabled={true}
          />
          <FormInput<BeneficiaryDetails>
            tooltip={label}
            label="Beneficiary last name"
            name="lastName"
            disabled={true}
          />
          <FormInput<BeneficiaryDetails>
            tooltip={label}
            label="Beneficiary birth date"
            name="birthDateFormatted"
            disabled={true}
          />
          <FormInput<BeneficiaryDetails>
            tooltip={label}
            label="Beneficiary address"
            name="addressFormatted"
            disabled={true}
          />
          <p>Subsidy amount to disburse</p>
          <Amount
            value={values.subsidyAmount}
            variant="hero"
          />
          <Button
            type="submit"
          >
            Proceed
          </Button>
        </Form>
      )}
    </Formik>
    </StepContainer>
  }
}