import React from 'react';
import {arrayOf, bool, element, InferProps, oneOfType,} from 'prop-types';
import Styles from './Form.scss';
import {Form as FormikForm} from 'formik';

const formProps = {
  children: arrayOf(oneOfType([element, bool]).isRequired).isRequired
};

type Props = InferProps<typeof formProps>;

export default class Form extends React.Component<Props, object> {
  static propTypes = formProps;

  render(): React.ReactElement {
    return  <FormikForm className={Styles.form}>
      {this.props.children}
    </FormikForm>
  }

}