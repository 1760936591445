import {Formik, FormikHelpers} from 'formik';
import React, {ReactElement} from 'react';
import Button from '../button/Button';
import Footer from '../disbursement/footer/Footer';
import StepContainer from '../disbursement/stepContainer/StepContainer';
import TopBar from '../disbursement/topBar/TopBar';
import Form from '../form/Form';
import FormInput from '../form/FormInput';
import http, {HttpError} from '../http';
import createLogger from '../logger';
import Styles from './Configuration.scss';

const CONFIGURATIONS_URL = '/configurations';

const convertTime12to24 = (time12h?: string): string | undefined => {
  if (!time12h) {
    return undefined;
  }

  const [time, modifier] = time12h.split(' ');
  const [hours, minutes] = time.split(':');

  let recountedHours = modifier === 'AM' && hours === '12' ? '00' : hours;

  if (modifier === 'PM' && parseInt(hours, 10) < 12) {
    recountedHours = (parseInt(hours, 10) + 12).toString();
  }

  console.error(`${recountedHours}:${minutes}`);

  return `${recountedHours}:${minutes}`;
}

const convertTime24to12 = (time24h?: string): string | undefined => {
  if (!time24h) {
    return undefined;
  }

  const [hours, minutes] = time24h.split(':');

  let recountedHours = parseInt(hours, 10);
  const modifier = recountedHours >= 12 ? 'PM' : 'AM';

  if (recountedHours === 0) {
    recountedHours = 12;
  }

  if (recountedHours > 12) {
    recountedHours = recountedHours - 12;
  }

  return `${recountedHours < 10 ? 0 + recountedHours : recountedHours}:${minutes} ${modifier}`;
}

interface ConfigurationField {
  code: string;
  id: number;
  value: string;
}

interface FormFields {
  BENEFICIARY_INVITATION_SMS_TEMPLATE?: string;
  PAYOUT_LOCATION_SMS_TEMPLATE?: string;
  PAYOUT_LOCATION_NOTIFICATION_START_TIME?: string;
  BENEFICIARY_PAYOUT_TIME?: string;
}

interface State {
  loaded: boolean;
  formInitialValues: {
    BENEFICIARY_INVITATION_SMS_TEMPLATE?: string;
    PAYOUT_LOCATION_SMS_TEMPLATE?: string;
    PAYOUT_LOCATION_NOTIFICATION_START_TIME?: string;
    BENEFICIARY_PAYOUT_TIME?: string;
  };
}

const logger = createLogger('configuration');

export default class Configuration extends React.Component<object, State> {
  constructor(props: object) {
    super(props);

    this.state = {
      loaded: false,
      formInitialValues: {
        BENEFICIARY_INVITATION_SMS_TEMPLATE: undefined,
        PAYOUT_LOCATION_SMS_TEMPLATE: undefined,
        PAYOUT_LOCATION_NOTIFICATION_START_TIME: undefined,
        BENEFICIARY_PAYOUT_TIME: undefined
      }
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      const response: ConfigurationField[] = await http.fetch(CONFIGURATIONS_URL)
      const updatedValues = Object.fromEntries(response.map(field => {
        const {code, value} = field;
        const fieldValue = code === 'BENEFICIARY_PAYOUT_TIME' ? convertTime12to24(value) : value;
        return [code, fieldValue];
      }));

      const formInitialValues = {
        ...this.state.formInitialValues,
        ...updatedValues
      };

      this.setState({
        loaded: true,
        formInitialValues
      });
    } catch (e) {
      logger.error('Error on getting configurations', e);
    }
  }


  submit = async (values: FormFields, config: FormikHelpers<FormFields>): Promise<void> => {
    try {
      const configurations = {
        ...values,
        BENEFICIARY_PAYOUT_TIME: convertTime24to12(values.BENEFICIARY_PAYOUT_TIME)
      };

      await http.fetch(CONFIGURATIONS_URL, {
        method: 'PUT',
        body: JSON.stringify({configurations})
      });
    } catch (e) {
      logger.error('Error on start identification', e);
      let errorMessage = 'Unknown error. Please check your internet connection.';
      if(e instanceof HttpError) {
        errorMessage = e.error.errorMessage;
      }

      config.setFieldError('server', errorMessage);
    }
  };


  render(): React.ReactElement {
    return <>
      <TopBar newClientButtonVisible={true} configurationButtonVisible={false} loaded={true} />
      <StepContainer label="Configuration">
        {
          !this.state.loaded ? <p>loading...</p> :
          <Formik
            initialValues={this.state.formInitialValues}
            enableReinitialize={true}
            onSubmit={this.submit}
          >
            {({isSubmitting}): ReactElement => (
              <>
                <p className={Styles.tableHeader}>List of the available SMS template variables:</p>
                <table className={Styles.table}>
                  <tbody>
                  <tr>
                    <th>variable</th>
                    <th>description</th>
                  </tr>
                  <tr>
                    <td>{'${referenceNo}'}</td>
                    <td>generated brn number</td>
                  </tr>
                  <tr>
                    <td>{'${paymentDate}'}</td>
                    <td>payment date specified in processing file</td>
                  </tr>
                  <tr>
                    <td>{'${paymentTime}'}</td>
                    <td>payment time specified in processing file</td>
                  </tr>
                  <tr>
                    <td>{'${paymentLocation}'}</td>
                    <td>payment location specified in processing file</td>
                  </tr>
                  </tbody>
                </table>
                <Form>
                  <FormInput<FormFields>
                    tooltip="SMS template that will be used to invite the beneficiary."
                    label="Beneficiary invitation SMS template"
                    name="BENEFICIARY_INVITATION_SMS_TEMPLATE"
                    textareaInput={true}
                  />
                  <FormInput<FormFields>
                    tooltip="SMS template that will be used to notify beneficiary about the payout location details."
                    label="Payout location SMS template"
                    name="PAYOUT_LOCATION_SMS_TEMPLATE"
                    textareaInput={true}
                  />
                  <FormInput<FormFields>
                    tooltip="Time when to start sending payout location notification."
                    label="Payout location notification start time"
                    name="PAYOUT_LOCATION_NOTIFICATION_START_TIME"
                    type='time'
                  />
                  <FormInput<FormFields>
                    tooltip="Default time when beneficiary can claim the subsidy. This value will be used if the beneficiary.paymentTime is null."
                    label="Beneficiary payout time"
                    name="BENEFICIARY_PAYOUT_TIME"
                    type='time'
                  />
                  <div className={Styles.buttonWrapper}>
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        }
      </StepContainer>
      <Footer />
    </>
  }
}