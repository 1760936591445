import React from 'react';
import StepContainer from '../stepContainer/StepContainer';
import Styles from './DoneStep.scss';
import Link from '../../link/Link';

export default class DoneStep extends React.Component<object, object> {
  static propTypes = {};

  constructor(props: object) {
    super(props);
  }

  render(): React.ReactElement {
    return <StepContainer label="Success">
      <div className={Styles.successMessage}>The process is complete.</div>
      <Link to="/" variant="button">
        New client
      </Link>
    </StepContainer>
  }
}