import React from 'react';
import PropTypes, {InferProps, oneOfType,} from 'prop-types';
import {Link as ReactLink} from 'react-router-dom';
import Styles from './Link.scss';
import Button from '../button/Button';

export type Variant = 'normal' | 'button' | 'buttonOutline';

const props = {
  to: PropTypes.string.isRequired,
  children: oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.string
};

type Props = Omit<InferProps<typeof props>, 'variant'> & {
  variant?: Variant;
};

export default class Link extends React.Component<Props, object> {
  static propTypes = props;

  render(): React.ReactElement {
    const variant = this.props.variant ?? 'normal';

    if(variant === 'normal') {
      return <ReactLink to={this.props.to}>
        {this.props.children}
      </ReactLink>
    }

    const buttonType = variant === 'button' ? 'normal' : 'outline';
    return <ReactLink
        to={this.props.to}
        className={Styles.button}
      >
        <Button
          type="button"
          variant={buttonType}>
        {this.props.children}
        </Button>
      </ReactLink>
  }
}