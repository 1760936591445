import React, {ReactElement} from 'react';
import PropTypes, {func, InferProps} from 'prop-types';
import FormInput from '../../form/FormInput';
import {Formik, FormikHelpers} from 'formik';
import Button from '../../button/Button';
import Form from '../../form/Form';
import http, {HttpError} from '../../http';
import createLogger from '../../logger';
import {Beneficiary} from '../Beneficiary';


const props = {
  onBeneficiaryRegistered: func.isRequired,
  referenceNumber: PropTypes.string.isRequired
};

type Props = Omit<InferProps<typeof props>, 'onBeneficiaryRegistered'> & {
  onBeneficiaryRegistered: (beneficiary: Beneficiary) => void;
}

interface FormFields {
  birthDate: string;
  server?: string;
}

const logger = createLogger('birthDateSection');
export default class BirthDateSection extends React.Component<Props, object> {
  static propTypes = props;

  submit = async (values: FormFields, config: FormikHelpers<FormFields>): Promise<void> => {
    try {
      const query = new URLSearchParams();
      query.append('referenceNo', this.props.referenceNumber);
      query.append('birthDate', values.birthDate);

      const beneficiary: Beneficiary = await http.fetch('/beneficiaries/identify-with-birth-date?' + query.toString(), {
        method: 'POST'
      });

      this.props.onBeneficiaryRegistered(beneficiary);
    } catch (e) {
      logger.error('Error on start identification with birth date', e);
      let errorMessage = 'Unknown error';
      if(e instanceof HttpError) {
        errorMessage = e.error.errorMessage;
      }

      config.setFieldError('server', errorMessage);
    }
  };

  render(): React.ReactElement {
    return <Formik
      initialValues={{birthDate: ''}}
      onSubmit={this.submit}
    >
      {({isSubmitting, errors}): ReactElement => (
        <Form>
          <FormInput<FormFields>
            tooltip="Enter beneficiary date of birth to display personal details"
            label="Beneficiary date of birth"
            type="date"
            name="birthDate"
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            error={errors.server}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  }
}