import React, {ReactElement} from 'react';
import {bool, func, InferProps} from 'prop-types';
import FormInput from '../../form/FormInput';
import {Formik, FormikHelpers} from 'formik';
import Button from '../../button/Button';
import Form from '../../form/Form';
import http, {HttpError} from '../../http';
import createLogger from '../../logger';

const props = {
  onStartIdentification: func.isRequired,
  disabled: bool.isRequired
};

type Props = Omit<InferProps<typeof props>, 'onStartIdentification'> & {
  onStartIdentification: (referenceNumber: string, identificationMethod: IdentificationMethod) => void;
}

interface FormFields {
  referenceNumber: string;
  server?: string;
}

export type IdentificationMethod = 'OTP' | 'BIRTH_DATE';

const logger = createLogger('refNumberSection');

export default class RefNumberSection extends React.Component<Props, object> {
  static propTypes = props;

  submit = async (values: FormFields, config: FormikHelpers<FormFields>): Promise<void> => {
    try {
      const query = new URLSearchParams();
      query.append('referenceNo', values.referenceNumber);

      const response: IdentificationMethod = await http.fetch('/beneficiaries/start-identification?' + query.toString(), {
        method: 'POST'
      });

      this.props.onStartIdentification(values.referenceNumber, response);
    } catch (e) {
      logger.error('Error on start identification', e);
      let errorMessage = 'Unknown error. Please check your internet connection.';
      if(e instanceof HttpError) {
        if(e.response.status === 404) {
          errorMessage = 'Reference number doesn\'t exist';
        } else {
          errorMessage = e.error.errorMessage;
        }
      }

      config.setFieldError('server', errorMessage);
    }
  };

  render(): React.ReactElement {
    return <Formik
      initialValues={{referenceNumber: '', otp: ''}}
      onSubmit={this.submit}
    >
      {({isSubmitting, errors}): ReactElement => (
        <Form>
          <FormInput<FormFields>
            tooltip="Enter beneficiary reference number to validate identity"
            label="Reference number"
            name="referenceNumber"
            disabled={this.props.disabled ?? false}
          />
          <Button
            type="submit"
            disabled={isSubmitting || (this.props.disabled ?? false)}
            error={errors.server}
          >
            Verify
          </Button>
        </Form>
      )}
    </Formik>
  }
}