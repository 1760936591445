import React, {ReactElement} from 'react';
import PropTypes, {InferProps} from 'prop-types';
import Styles from './FormInput.scss';
import {Field as FormikField, FieldProps} from 'formik';
import classnames from 'classnames';

const fieldProps = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  tooltip: PropTypes.string,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  textareaInput: PropTypes.bool
};

type Props<FormFields> = Omit<InferProps<typeof fieldProps>, 'variant'> & {
  name: {
    [F in keyof FormFields]: FormFields[F] extends object ? never : F
  }[keyof FormFields];
} & {
  variant?: 'normal' | 'fullWidth';
}

class FormInput<FormFields> extends React.Component<Props<FormFields>, object> {
  static propTypes = fieldProps;

  render(): React.ReactElement {
    const props = this.props;
    return <div className={classnames(Styles.inputWrapper, {
          [Styles.fullWidth]: this.props.variant === 'fullWidth'
        })}>
        <FormikField
          name={props.name}
        >
          {({field, meta,}: FieldProps<string>): ReactElement => (
              this.props.textareaInput ?
                <textarea
                  className={classnames(Styles.input, Styles.textarea, {[Styles.inputError]: meta.touched && meta.error})}
                  disabled={props.disabled ?? false}
                  {...field}></textarea>
                : <input
                  className={classnames(Styles.input, {[Styles.inputError]: meta.touched && meta.error})}
                  type={props.type ?? 'text'}
                  disabled={props.disabled ?? false}
                  {...field}></input>
            )
          }
        </FormikField>
        <label className={Styles.label}>{props.label}</label>
        { this.props.tooltip && <span className={Styles.tooltip}>{this.props.tooltip}</span> }
      </div>
  }
}

export default FormInput;