import React, {ReactElement} from 'react';
import LogoRcbc from '../../static/assets/images/logo-rcbc.png';
import Styles from './Login.scss';
import Button from '../button/Button';
import FormInput from '../form/FormInput';
import {Formik, FormikHelpers} from 'formik';
import auth from '../auth';
import {HttpError} from '../http';
import * as Yup from 'yup';
import createLogger from '../logger';

import Logo, {LogoVariant} from '../logo/Logo';
import Form from '../form/Form';
import {Redirect} from 'react-router';
import {InferProps} from 'prop-types';

interface FormFields {
  email: string;
  password: string;
  server?: string;
}

const logger = createLogger('login');

const LoginSchema = Yup.object().shape({
  email: Yup.string().notRequired(),
    // .email('Wrong email format')
    // .required('Email is required'),
  password: Yup.string().notRequired()
    // .required('Password is required'),
});

type Props = InferProps<object>;

interface State {
  loggedIn: boolean;
}

export default class Login extends React.Component<Props, State> {
  constructor(props: object) {
    super(props);

    this.state = {
      loggedIn: false
    }
  }

  submit = async (values: FormFields, config: FormikHelpers<FormFields>): Promise<void> => {
    try {
      await auth.login(values.email, values.password);

      this.setState({
        loggedIn: true
      });
    } catch (e) {
      logger.error('Login error', e);
      let errorText = 'Error communicating with server';
      if(e instanceof HttpError && e.response.status == 401) {
        errorText = 'Invalid credentials';
      }

      config.setFieldError('server', errorText);
    }
  };

  render(): React.ReactElement {
    if(this.state.loggedIn) {
      return <Redirect to="/" />
    }

    return <div className={Styles.container}>
        <img className={Styles.rcbcLogo} src={LogoRcbc} alt="RCBC"/>
        <h1 className={Styles.header}>
          RCBC DiskarTech Lite Disbursement Portal
        </h1>
        <Formik<FormFields>
          initialValues={{ email: '', password: '' }}
          onSubmit={this.submit}
          validationSchema={LoginSchema}
        >
          {({
            isSubmitting,
            errors
          }): ReactElement => (
            <Form>
              <FormInput<FormFields>
                label="Username"
                type="text"
                variant="fullWidth"
                name="email">
              </FormInput>
              <FormInput<FormFields>
                label="Password"
                type="password"
                variant="fullWidth"
                name="password">
              </FormInput>
              <Button
                type="submit"
                fullWidth={true}
                disabled={isSubmitting}
                error={errors.server}
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
        <p>Powered by</p>
        <Logo variant={LogoVariant.NEXTBANK} />
      </div>;
  }
}
